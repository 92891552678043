<template>
  <div class="map-results__body-table">
    <div v-for="(item, i) in results"
         :key="`item-${i}`"
         class="table-item"
         :class="{ last: i === (results.length) - 1 && results.length === 3 }">
      <div class="table-item__wrapper" v-if="item.type === 'input'">
        <div class="table-item__text">{{ item.text }}</div>
        <div class="table-item__input">
          <input
            type="number"
            v-model.trim="userValue"
            @input="setValue(item.index)">
        </div>
        <div class="table-item__val">{{ item.unit }}</div>
      </div>
      <div  class="table-item__result" v-if="item.type === 'input'">
        <div class="table-item__text">{{ item.result_text }}</div>
        <div class="table-item__val">{{ item.val }}</div>
        <div class="table-item__unit">{{ item.result_unit }}</div>
      </div>
      <div class="table-item__wrapper" v-if="item.type !== 'input'">
        <div class="table-item__text">{{ item.text }}</div>
        <div class="table-item__val">{{ item.val }} {{ item.unit }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { frameMixin } from '@/mixins/frame-mixin'

export default {
  props: {
    results: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  mixins: [frameMixin],
  name: 'ResultsTable',
  mounted() {
    this.updateFrameSize()
  },
  data: () => {
    return {
      userValue: ''
    }
  },
  inject: ['getResultForUserInput'],
  methods: {
    setValue(index) {
      // Ограничения вводимых значений (высоты над уровнем моря для Беларуси). Валидация добавится позже
      // const limits = this.userValue >= 14 && this.userValue <= 331
      if (this.userValue === '') return
      this.getResultForUserInput({ value: this.userValue, index })
    }
  },
  watch: {
    results: function (newVal, oldVal) {
      const oldTitle = oldVal.find(result => result.type === 'title')
      const newTitle = newVal.find(result => result.type === 'title')

      if (oldTitle?.val !== newTitle?.val) {
        this.userValue = ''
      }
    }
  }
}
</script>

<style scoped lang="sass">
.map-results
  &__body
    &-table
      display: grid
      align-items: start
      +media((grid-template-columns: (320: 1fr, 768: 1fr 1fr)))
      +media((column-gap: (768: rem(24), 1024: rem(48), 1600: rem(68))))
      color: $black_A
      .table-item
        &__wrapper
          display: flex
          align-items: baseline
          justify-content: space-between
          +media((padding: (320: rem(12) 0, 768: rem(16) 0)))
        &__input
          display: flex
          flex-grow: 1
          justify-content: flex-end
          margin: 0 5px
          input
            max-width: 40px
            padding: 0.4375rem 0.625rem
            border-radius: 0.25rem
            text-decoration: none
            border: 1px solid #999999
        &__result
          display: flex
          width: 100%
          +media((padding: (320: rem(12) 0, 768: rem(16) 0)))
          .table-item__val
            flex-grow: 1
            padding: 0 5px
            text-align: right
        &__text, &__val, &__input, &__unit
          @extend %14
        &__text

          +media((padding-right: (320: rem(20), 768: rem(32), 1200: rem(48))))
        &__val, &__unit
          +media((min-width: (320: rem(80), 1200: rem(96))))
          font-weight: bold

        &:not(:last-child)
          +media((box-shadow: (320: inset 0 rem(-1) 0 $cool_B, 768: none)))
        &:not(:nth-last-child(-n + 2))
          +media((box-shadow: (768: inset 0 rem(-1) 0 $cool_B)))
        &.last
          +media((box-shadow: (768: 0 rem(-1) 0 $cool_B)))
          +media((grid-column-start: (768: 2)))
</style>
